import React, { useState, useEffect } from "react"
import { Helmet } from "react-helmet";
import { Button, Navbar, Nav, Form, Container, FormControl, Row, Col } from 'react-bootstrap';
import Select from 'react-select';
import axios from "axios"
import { isMobile } from "react-device-detect";
import { Link } from "@StarberryUtils";
import HTMLParser from 'html-react-parser';

import Slider from "react-slick";
import Header from '../../../components/Header/Header';
import Footer from '../../../components/Footer/Footer';
import PropertySaleFooter from '../../../components/Footer/PropertySaleFooter';
import SearchResultSchema from "../../../components/SearchResultSchema/SearchResultSchema";
// import IncludeSold from '../../../components/SearchResult/filterSold'
import {randomArrayShuffle, amenitiesSliderArray, amenitiesCheckboxArray, priceminRangeSale, pricemaxRangeSale, SearchBoxOptions, SaleType, MarketAds} from "../../../components/common/utils";
import {PropertyTypes} from "../../../components/common/PropertyTypes";
import NoResultList from '../../../components/SearchResult/ResultList/NoResultList';
// 
import $ from "jquery"
import qs from "qs"
import algoliasearch from "algoliasearch/lite"
import {
  connectStats,
  SortBy,
  connectInfiniteHits,
  InstantSearch,
  RefinementList,
  connectSearchBox,
  connectMenu,
  connectRange,
  Configure,
  connectSortBy,
  ToggleRefinement,
  MenuSelect,
  connectRefinementList,
  connectToggleRefinement
} from "react-instantsearch-dom"
import {
  GoogleMapsLoader,
  GeoSearch,
  Marker,
  CustomMarker
} from 'react-instantsearch-dom-maps'
import Cookies from 'universal-cookie'
import { navigate } from "@reach/router"
import { parseSearchUrl,  propertyH1 } from "../../../components/SearchResult/utils";
import { generateMetaDescription, generateIntroCopy } from "../../../components/SearchResult/SeoContentUtils/residentialUtils";
import SeoResults from "../../../components/SearchResult/seoResults"
import "../assets/styles/_index.scss"
//

// 
import { ShowProcessedImage } from '../../../components/common/ggfx-client/module/components/show-image';
import imageConfig from "../../../../static/images/config.json"
import noImage from "../../../images/no-image.jpg"
import propertyImg03 from "../../../images/result/property-img03.jpg"
import bgLogoImg from "../../../images/result/bg-logo-img.png"
import PropertyContactCard from "../../../components/Home/ContactCard/PropertyContactCard"
import acHeatingImg from "../../../images/result/acHeating.jpg"
import balconyImg from "../../../images/result/balcony.jpg"
import conciergeServiceImg from "../../../images/result/conciergeService.jpg"
import gymImg from "../../../images/result/gym.jpg"
import kitchenImg from "../../../images/result/kitchen.jpg"
import parkingImg from "../../../images/result/parking.jpg"
import securityImg from "../../../images/result/security.jpg"
import sharedPoolImg from "../../../images/result/sharedPool.jpg"
import viewOfWaterImg from "../../../images/result/viewOfWater.jpg"
import wardrobesImg from "../../../images/result/wardrobes.jpg"
import PropertyFooter from "../../../components/Footer/PropertyFooter";
import IncludeLet from "../../../components/SearchResult/filterLet";
// 

const mycookies = new Cookies();
const updateAfter = 500

var minpriceval = minpriceval || ""
var maxpriceval = maxpriceval || ""
var minbedroomval = minbedroomval || ""
var maxbedroomval = maxbedroomval || ""
var minsqftval = minsqftval || ""
var maxsqftval = maxsqftval || ""
var includeSold = false;
var accodamationSummaryList = [];

const searchClient = algoliasearch(
  `${process.env.GATSBY_ALGOLIA_APP_ID}`,
  `${process.env.GATSBY_ALGOLIA_SEARCH_KEY}`
)
const index_name = `${process.env.GATSBY_ALGOLIA_INDEX_NAME}`

// Capitalize
const capitalize = (s) => {
  if (typeof s !== 'string') return ''
  return s.charAt(0).toUpperCase() + s.slice(1)
}
// Capitalize

// Property results ad block
var ad_slot = 0;
// Property results ad block

// const[ad_block, setAdBlock] = useState('');

//console.log("amenitiesSliderArray", amenitiesSliderArray);

// Property results loop
const InfiniteHits = ({
  hits,
  hasPrevious,
  refinePrevious,
  hasMore,
  refineNext,
  location,
}) => {

  // const[ad_block, setAdBlock] = useState('');

  //console.log("hits==>", hits);


  return (
    <>
      <>
        {(hits.length > 0 && hasPrevious) && (
          <button
            id="myprevbut"
            onClick={refinePrevious}
            disabled={!hasPrevious}
            className="d-none"
          >
            Show prev
          </button>
        )}
      </>

      {
        hits.map((hit, i) => {

          // property details url structure
          let uriStr = "";

                if(hit.search_type === "sales" ) {
                  uriStr = `properties-for-sale/`
                }
                if(hit.status === "sold" ) {
                  uriStr = `properties-sold/`
                }
                // property details url structure

          // Ad block
          ad_slot = i;
          // Ad block

          return (
            <>
              <Col md={6} xl={4} key={i} id={`mySaleProp${hit.objectID}`} className={`${hit.status}`}>
                <div className="animated">
                  <div className="contact-card">
                    <figure>
                      <Link id={`mySaleProp${hit.objectID}`}
                        onClick={() => mycookies.set('mySaleProp', hit.objectID, { path: '/', maxAge: 300 })}
                        to={`/${uriStr}${hit.slug}-${hit.objectID}`}
                        state={{ referrer: location.pathname }}>
                        <span className="overlay_bg"></span>
                        {
                          hit.images && (hit.images.length > 0) ? (
                            <ShowProcessedImage images={hit.images[0]} attr={{ className: 'propery-img', alt: '' }} transforms={imageConfig.property.searchResults.sizes} />
                          ) : (
                            <img src={noImage} alt="" />
                          )
                        }
                      </Link>

                      {hit.status === "sold" && <span className="card-tag d-block">SOLD</span>}
                    </figure>

                    <Link id={`mySaleProp${hit.objectID}`}
                      onClick={() => mycookies.set('mySaleProp', hit.objectID, { path: '/', maxAge: 300 })}
                      to={`/${uriStr}${hit.slug}-${hit.objectID}`}
                      state={{ referrer: location.pathname }}>
                      <div className="info">
                        <h6 className="card-title">{hit.title}</h6>
                        <span className="date">{hit.price.toLocaleString() + " AED"}</span>
                      </div>
                    </Link>
                  </div>
                </div>
              </Col>
              {
                hits.length > 3 &&
                (
                  (i % 4 === 1) &&
                  <CustomAmenitiesBlock attribute="accommodation_summary" limit={100} />
                )
              }

            </>
          )
        })
      }

      <Container>
        <Row>
          <Col lg={12} className="mb-5 text-center">
            {(hits.length > 0 && hasMore) && (
              <button
                id="mynextbut"
                onClick={refineNext}
                disabled={!hasMore}
                className="btn btn-primary"
              >
                <span>Load more</span>
              </button>
            )}
          </Col>
        </Row>
      </Container>
    </>
  )
}

const CustomInfiniteHits = connectInfiniteHits(InfiniteHits)
// Property results loop

// Property Amenities block
const AmenitiesBlock = ({ items, currentRefinement, refine }) => {
  const [amenities, setAmenities] = useState([]);
  var amenities_arr = [];
  useEffect(() => {
    randomArrayShuffle(items).slice(0, 1).map(item => {
      amenities_arr.push(item)
    })
    setAmenities(amenities_arr);
  }, [])

  //console.log("amenities ads", amenities);

  return (
    amenities && (amenities.length > 0) ?
      <>
        {amenities.map(item => <>
          <Col md={6} lg={4} key={item.value} className={item.isRefined ? 'amenities_block active' : 'amenities_block'}>
            <div className="animated">
              <div className="info-card d-flex align-items-center">
                <img className="cardbg-logo" src={bgLogoImg} alt="img" />
                <div className="wrap">
                  <h2 className="info-title">
                    {
                      item.label === "central-a-c-heating" ? "Central A/C & Heating"
                        : item.label === "built-kitchen-appliances" ? "Built in Kitchen Appliances"
                          : item.label === "built-wardrobes" ? "Built in Wardrobes"
                            : item.label === "walk-closet" ? "Walk in Closet"
                              : item.label === "dining-building" ? "Dining in Building"
                                : item.label === "retail-building" ? "Retail in Building"
                                  : capitalize(item.label.replace(/-/g, ' '))
                    }
                  </h2>
                  <p className="info-desc d-none d-xl-block">Do you want to see properties with {item.label === "central-a-c-heating" ? "Central A/C & Heating"
                    : item.label === "built-kitchen-appliances" ? "Built in Kitchen Appliances"
                      : item.label === "built-wardrobes" ? "Built in Wardrobes"
                        : item.label === "walk-closet" ? "Walk in Closet"
                          : item.label === "dining-building" ? "Dining in Building"
                            : item.label === "retail-building" ? "Retail in Building"
                              : capitalize(item.label.replace(/-/g, ' '))
                  }.
                  </p>
                  <p className="info-desc d-xl-none">Do you want to see properties with {item.label === "central-a-c-heating" ? "Central A/C & Heating"
                    : item.label === "built-kitchen-appliances" ? "Built in Kitchen Appliances"
                      : item.label === "built-wardrobes" ? "Built in Wardrobes"
                        : item.label === "walk-closet" ? "Walk in Closet"
                          : item.label === "dining-building" ? "Dining in Building"
                            : item.label === "retail-building" ? "Retail in Building"
                              : capitalize(item.label.replace(/-/g, ' '))
                  }.
                  </p>
                  <Link to={"/properties/for-sale/in-dubai/with-" + item.value} className="btn btn-primary amenities-block" onClick={event => { $("html, body").animate({ scrollTop: 0 }, 500); }}><span>View Properties</span></Link>
                </div>
              </div>
            </div>
          </Col>

          {/* Market Ads block */}
          <MarketAds />
        </>
        )}
      </>
      : null
  )
}
const CustomAmenitiesBlock = connectMenu(AmenitiesBlock);
// Property Amenities block



// Property No Results
const NoStats = ({ processingTimeMS, nbHits }) => {
  return (
    <>
      {nbHits === 0 &&
        <Container>
          <Row>
            <Col lg={12} className="text-center mb-5">
              <div>Unfortunately, we do not currently have any properties that match your search criteria.</div>

              <NoResultList uriStr={'properties-for-sale'} status={'for sale'} />
            </Col>
          </Row>
        </Container>
      }
    </>
  )
}

const CustomNoStats = connectStats(NoStats)
// Property No Results

// Property Results count
const Stats = ({ processingTimeMS, nbHits }) => <>{nbHits}</>

const CustomStats = connectStats(Stats)
// Property Results count

// Searchbox
const SearchBox = connectSearchBox(({ currentRefinement, refine, areaOptions}) => {

  const [search_box, setSearchBox] = useState('');

    var search_box_option = areaOptions; 

  //console.log("currentRefinement", currentRefinement)
  return (
    <Form.Group className="prop-loc property-search-area">
      {/* <Form.Label>Property Location</Form.Label> */}
      <div className="select-group">
        <Form.Control type="text" className="postcode_input search_text" placeholder="Street, Area"
          onChange={event => refine(event.currentTarget.value.replace(/ /g, "-").toLowerCase())}
          value={currentRefinement.replace(/-/g, ' ')}
        />

        <Select
          className="postcode_input form-control search_area_selct"
          name="search_area"
          placeholder={`Street, Area`}
          options={search_box_option}
          onChange={event => { refine(event.value.replace(/ /g, "-").toLowerCase()) }}
          isSearchable={false}
        />
      </div>
    </Form.Group>
  )
})
// Searchbox

// Property Min Price
const priceminRange = priceminRangeSale

const minpriceSelect = ({
  currentRefinement,
  min,
  max,
  precision,
  refine,
}) => {
  let minPriceRange = priceminRange;
  if (currentRefinement.max !== undefined && currentRefinement.max != "0") {
    minPriceRange = priceminRange.filter((x) => parseInt(x.value) < parseInt(currentRefinement.max))
  }

  return (
    <Form.Group className="price-filter">
      {/* <Form.Label>Min Price</Form.Label> */}
      <div className="select-group">
        <Select
          name="price"
          options={minPriceRange}
          defaultValue={currentRefinement.min || "0"}
          classNamePrefix={"select-opt"}
          placeholder={currentRefinement.min === undefined || null || 0 ? "Min Price" : currentRefinement.min.toLocaleString() + " AED"}
          onChange={(e) => {
            minpriceval = e.value
            if (e.value === "0") {
              if (maxpriceval) {
                refine({
                  max: maxpriceval,
                })
              } else refine(e.value, e.label)
            } else {
              refine({
                min: e.value,
                max: maxpriceval,
              })
            }
          }}
          isSearchable={false}
        />
      </div>
    </Form.Group>
  )
}

const CustomminpriceSelect = connectRange(minpriceSelect)
// Property Min Price

// Property Max Price
const pricemaxRange = pricemaxRangeSale

const maxpriceSelect = ({
  currentRefinement,
  min,
  max,
  precision,
  refine,
}) => {

  let maxPriceRange = pricemaxRange;
  let maxpricedisable = ''
  if (currentRefinement.min !== undefined) {
    maxPriceRange = pricemaxRange.filter((x) => x.value == "0" || parseInt(x.value) > parseInt(currentRefinement.min))
  }
  if (currentRefinement.min == "10000000") {
    maxpricedisable = "disabled"
  }

  return (
    <Form.Group className="price-filter">
      {/* <Form.Label>Max Price</Form.Label> */}
      <div className="select-group">
        <Select
          name="price"
          options={maxPriceRange}
          defaultValue={currentRefinement.max || "0"}
          classNamePrefix={"select-opt"}
          placeholder={currentRefinement.max === undefined || null || 0 ? "Max Price" : currentRefinement.max.toLocaleString() + " AED"}
          onChange={(e) => {
            maxpriceval = e.value
            if (e.value === "0") {
              if (minpriceval) {
                refine({
                  min: minpriceval,
                })
              } else refine(e.value, e.label)
            } else {
              refine({
                min: minpriceval,
                max: e.value,
              })
            }
          }}
          isSearchable={false}
        />
      </div>
    </Form.Group>
  )
}

const CustommaxpriceSelect = connectRange(maxpriceSelect)
// Property Max Price

// Property type
const buildingSelectbox = ({ items, currentRefinement, refine }) => (
    <Form.Group>
            {/* <Form.Label>Property Type</Form.Label> */}
            <div className="select-group"> 
                  <PropertyTypes
                    name="seobuilding"
                    propertyTypeName={"Residential_Property_Types"} 
                    placeholder={currentRefinement === null || undefined || "" ? "Property Type" : capitalize(currentRefinement).replace(/-/g, " ")}
                    classNamePrefix={"select-opt"}
                    onChange={(e) => {refine(e.value, e.label)}}
                    isSearchable={false}
                />
            </div>
    </Form.Group>
)

const CustombuildingSelectbox = connectMenu(buildingSelectbox)
// Property type

// Property Min Bedroom
const bedroomminRange = [
  {
    value: "",
    label: "Beds"
  },
  {
    value: "0",
    label: "Studio"
  },
  {
    value: "1",
    label: "1 +"
  },
  {
    value: "2",
    label: "2 +"
  },
  {
    value: "3",
    label: "3 +"
  },
  {
    value: "4",
    label: "4 +"
  },
  {
    value: "5",
    label: "5 +"
  },
  // {
  //   value: "6",
  //   label: "6 +"
  // },
  // {
  //   value: "7",
  //   label: "7 +"
  // },
  // {
  //   value: "8",
  //   label: "8 +"
  // },
  // {
  //   value: "9",
  //   label: "9 +"
  // },
  // {
  //   value: "10",
  //   label: "10 +"
  // },
  // {
  //   value: "11",
  //   label: "11 +"
  // },
  // {
  //   value: "12",
  //   label: "12 +"
  // },
  // {
  //   value: "13",
  //   label: "13 +"
  // },
  // {
  //   value: "14",
  //   label: "14 +"
  // },
  // {
  //   value: "15",
  //   label: "15 +"
  // },
  // {
  //   value: "16",
  //   label: "16 +"
  // },
  // {
  //   value: "17",
  //   label: "17 +"
  // },
  // {
  //   value: "18",
  //   label: "18 +"
  // },
  // {
  //   value: "19",
  //   label: "19 +"
  // },
  // {
  //   value: "20",
  //   label: "20 +"
  // }
]

const minbedroomSelect = ({
  currentRefinement,
  min,
  max,
  precision,
  refine,
}) => {
  let minBedroomRange = bedroomminRange;
  if (currentRefinement.max !== undefined && currentRefinement.max != "0") {
    minBedroomRange = bedroomminRange.filter((x) => parseInt(x.value) < parseInt(currentRefinement.max))
  }

  return (
    <Form.Group className="bed-filter">
      {/* <Form.Label>Min Bedrooms</Form.Label> */}
      <div className="select-group">
        <Select
          name="bedroom"
          options={minBedroomRange}
          defaultValue={currentRefinement.min || ""}
          classNamePrefix={"select-opt"}
          placeholder={
            currentRefinement.min === undefined || null ? "Beds"
              : currentRefinement.min === 0 ? "Studio"
                : currentRefinement.min + " +"
          }
          // onChange={(e) => {
          //     minbedroomval = e.value
          //     if (e.value === "0") {
          //         if (maxbedroomval) {
          //             refine({
          //                 max: maxbedroomval,
          //             })
          //         } else refine(e.value, e.label)
          //     } else {
          //         refine({
          //             min: e.value,
          //             max: maxbedroomval,
          //         })
          //     }
          // }}

          onChange={event => {
            if (event.value === "") {
              refine(event.value)
            } else if (event.value === "0") {
              refine({
                max: event.value,
              })
            } else {
              refine({
                min: event.value,
              })
            }
          }}

          // value={{label: (currentRefinement.min > 1 ? currentRefinement.min+' +' : 'Any') || 'Min Bedrooms'}}
          isSearchable={false}
        />
      </div>
    </Form.Group>
  )
}

const CustomminbedroomSelect = connectRange(minbedroomSelect)
// Property Min Bedroom

// Property Max Bedroom
const bedroommaxRange = [
  {
    value: "0",
    label: "Any"
  },
  {
    value: "1",
    label: "1 +"
  },
  {
    value: "2",
    label: "2 +"
  },
  {
    value: "3",
    label: "3 +"
  },
  {
    value: "4",
    label: "4 +"
  },
  {
    value: "5",
    label: "5 +"
  },
  // {
  //   value: "6",
  //   label: "6 +"
  // },
  // {
  //   value: "7",
  //   label: "7 +"
  // },
  // {
  //   value: "8",
  //   label: "8 +"
  // },
  // {
  //   value: "9",
  //   label: "9 +"
  // },
  // {
  //   value: "10",
  //   label: "10 +"
  // },
  // {
  //   value: "11",
  //   label: "11 +"
  // },
  // {
  //   value: "12",
  //   label: "12 +"
  // },
  // {
  //   value: "13",
  //   label: "13 +"
  // },
  // {
  //   value: "14",
  //   label: "14 +"
  // },
  // {
  //   value: "15",
  //   label: "15 +"
  // },
  // {
  //   value: "16",
  //   label: "16 +"
  // },
  // {
  //   value: "17",
  //   label: "17 +"
  // },
  // {
  //   value: "18",
  //   label: "18 +"
  // },
  // {
  //   value: "19",
  //   label: "19 +"
  // },
  // {
  //   value: "20",
  //   label: "20 +"
  // }
]

const maxbedroomSelect = ({
  currentRefinement,
  min,
  max,
  precision,
  refine,
}) => {

  let maxBedroomRange = bedroommaxRange;
  let maxbedroomdisable = ''
  if (currentRefinement.min !== undefined) {
    maxBedroomRange = bedroommaxRange.filter((x) => x.value == "0" || parseInt(x.value) > parseInt(currentRefinement.min))
  }
  if (currentRefinement.min == "20") {
    maxbedroomdisable = "disabled"
  }

  return (
    <Form.Group>
      {/* <Form.Label>Max Bedrooms</Form.Label> */}
      <div className="select-group">
        <Select
          name="bedroom"
          options={maxBedroomRange}
          defaultValue={currentRefinement.max || "0"}
          classNamePrefix={"select-opt"}
          placeholder={currentRefinement.max === undefined || null || 0 ? "Max Bedrooms" : currentRefinement.max + " +"}
          onChange={(e) => {
            maxbedroomval = e.value
            if (e.value === "0") {
              if (minbedroomval) {
                refine({
                  min: minbedroomval,
                })
              } else refine(e.value, e.label)
            } else {
              refine({
                min: minbedroomval,
                max: e.value,
              })
            }
          }}
          isSearchable={false}
        />
      </div>
    </Form.Group>
  )
}

const CustommaxbedroomSelect = connectRange(maxbedroomSelect)
// Property Max Bedroom

// Dropdown react select styles
const customStyles = {
  option: (styles, { data, isDisabled, isFocused, isSelected }) => {
    return {
      ...styles,
      backgroundColor: isFocused ? "#C6974D" : null,
      color: isFocused ? "#ffffff" : "#231F20"
    };
  },
  control: styles => ({
    ...styles,
    backgroundColor: null,
    border: 0,
    outline: 0,
    boxShadow: 'none',
    color: '#ffffff',
    fontSize: '0.875rem',
    padding: '10',
  }),
  placeholder: (defaultStyles) => {
    return {
      ...defaultStyles,
      color: '#ffffff',
    }
  },
  singleValue: (defaultStyles) => {
    return {
      ...defaultStyles,
      color: '#ffffff',
    }
  },
  valueContainer: (styles) => ({
    ...styles,
    fontSize: '0.875rem',
    padding: 10,
  })
}

const DownIndicator = () => (
  <>
    <i className={"search-filter-dropdown"}></i>
  </>
)
// Dropdown react select styles

// Property Min Sqft
const sqftminRange = [
  {
    value: "0",
    label: "Min Sqft"
  },
  {
    value: "100",
    label: "100"
  },
  {
    value: "200",
    label: "200"
  },
  {
    value: "300",
    label: "300"
  },
  {
    value: "400",
    label: "400"
  },
  {
    value: "500",
    label: "500"
  }
]

const minsqftSelect = ({
  currentRefinement,
  min,
  max,
  precision,
  refine,
  min_size,
  max_size,
  setMinRange
}) => {
  let minSqftRange = sqftminRange;
  // if (currentRefinement.max !== undefined && currentRefinement.max != "0") {
  //   minSqftRange = sqftminRange.filter((x) => parseInt(x.value) < parseInt(currentRefinement.max))
  // }

  if (max_size) {
    minSqftRange = sqftminRange.filter((x) => parseInt(x.value) < parseInt(max_size))
  }

  //console.log("prop_size",min_size, max_size)

  return (
    <Form.Group className="mb-0">
      <div className="select-group">
        <Select
          name="size"
          options={minSqftRange}
          defaultValue={currentRefinement.min || "0"}
          classNamePrefix={"amenitiespopup_select select-opt"}
          placeholder="Min Sqft"
          // onChange={(e) => {
          //     minsqftval = e.value
          //     if (e.value === "0") {
          //         if (maxsqftval) {
          //             refine({
          //                 max: maxsqftval,
          //             })
          //         } else refine(e.value, e.label)
          //     } else {
          //         refine({
          //             min: e.value,
          //             max: maxsqftval,
          //         })
          //     }
          // }}
          onChange={(e) => setMinRange(e.value)}
          isSearchable={false}
          styles={customStyles}
          components={{ DropdownIndicator: () => <DownIndicator /> }}
        />
      </div>
    </Form.Group>
  )
}

const CustomminsqftSelect = connectRange(minsqftSelect)
// Property Min Sqft

// Property Max Sqft
const sqftmaxRange = [
  {
    value: "",
    label: "Max Sqft"
  },
  {
    value: "100",
    label: "100"
  },
  {
    value: "200",
    label: "200"
  },
  {
    value: "300",
    label: "300"
  },
  {
    value: "400",
    label: "400"
  },
  {
    value: "500",
    label: "500"
  }
]

const maxsqftSelect = ({
  currentRefinement,
  min,
  max,
  precision,
  refine,
  min_size,
  max_size,
  setMaxRange
}) => {

  let maxSqftRange = sqftmaxRange;
  let maxsqftdisable = ''
  // if (currentRefinement.min !== undefined) {
  //   maxSqftRange = sqftmaxRange.filter((x) => x.value == "" || parseInt(x.value) > parseInt(currentRefinement.min))
  // }
  // if (currentRefinement.min == "500") {
  //   maxsqftdisable = "disabled"
  // }

  if (min_size) {
    maxSqftRange = sqftmaxRange.filter((x) => x.value == "" || parseInt(x.value) > parseInt(min_size))
  }
  if (min_size === "500") {
    maxsqftdisable = "disabled"
  }

  //console.log("prop_size",min_size, max_size)

  return (
    <Form.Group className="mb-0">
      <div className="select-group">
        <Select
          name="size"
          options={maxSqftRange}
          defaultValue={currentRefinement.max || ""}
          classNamePrefix={"amenitiespopup_select select-opt"}
          placeholder="Max Sqft"
          // onChange={(e) => {
          //     maxsqftval = e.value
          //     if (e.value === "0") {
          //         if (minsqftval) {
          //             refine({
          //                 min: minsqftval,
          //             })
          //         } else refine(e.value, e.label)
          //     } else {
          //         refine({
          //             min: minsqftval,
          //             max: e.value,
          //         })
          //     }
          // }}
          onChange={(e) => setMaxRange(e.value)}
          isSearchable={false}
          styles={customStyles}
          components={{ DropdownIndicator: () => <DownIndicator /> }}
        />
      </div>
    </Form.Group>
  )
}

const CustommaxsqftSelect = connectRange(maxsqftSelect)
// Property Max Sqft

// Property Amenities
const AmenitiesMenuSelect = ({ items, currentRefinement, refine }) => {
  // setAdBlock(items);

  var optionsArr = [];

  optionsArr = [{ label: 'All', value: '' }];

  items && (items.length > 0) && items.map((item, index) => {
    optionsArr.push(item);
  })

  return (
    items && (items.length > 0) ?
      <Form.Group>
        {/* <Form.Label>Amenities</Form.Label> */}
        <div className="select-group">
          <Select
            name="accommodation_summary"
            options={optionsArr}
            defaultValue={currentRefinement || ''}
            placeholder="Amenities"
            classNamePrefix={"select-opt"}
            onChange={(e) => { refine(e.value, e.label) }}
            isSearchable={false}
          />
        </div>
      </Form.Group>
      : null
  )
}

const CustomMenuSelect = connectMenu(AmenitiesMenuSelect);
// Property Amenities

// Property Sale type
const SaleTypeMenuSelect = ({ items, currentRefinement, refine }) => {

  var optionsArr = [];

  optionsArr = [{ label: 'All', value: '' }];

  items && (items.length > 0) && items.map((item, index) => {
    optionsArr.push(item);
  })

  return (
    // items && (items.length > 0) ?
    <Form.Group>
      <div className="select-group">
        {/* <Select
                name="sublisting_type"
                options={optionsArr}
                defaultValue={currentRefinement || ''}
                placeholder="Sale Type"
                classNamePrefix={"select-opt"}
                onChange={(e) => {refine(e.value, e.label)}}
                isSearchable={false}
            /> */}
        <Select
          name="sublisting_type"
          options={SaleType}
          placeholder={currentRefinement === null || undefined || "" ? "Sale Type" : capitalize(currentRefinement)}
          classNamePrefix={"select-opt"}
          onChange={(e) => { refine(e.value, e.label) }}
          isSearchable={false}
        />
      </div>
    </Form.Group>
    // : null
  )
}

const CustomMenuSaleType = connectMenu(SaleTypeMenuSelect);
// Property Sale type

// Property Furnishing
const FurnishingMenuSelect = ({ items, currentRefinement, refine, furnish_type, setFurnishType }) => {

  var optionsArr = [];

  optionsArr = [{ label: 'All', value: '' }];

  items && (items.length > 0) && items.map((item, index) => {
    optionsArr.push(item);
  })


  return (
    // items && (items.length > 0) ?
    <Form.Group>
      <div className="select-group">
        <Select
          name="furnished"
          // options={optionsArr}
          options={[
            { value: "", label: "All" },
            { value: "fully furnished", label: "Fully Furnished" },
            { value: "Unfurnished", label: "Unfurnished" },
            { value: "semi furnished", label: "Semi Furnished" },
          ]}
          defaultValue={currentRefinement || ''}
          placeholder="Furnishing"
          classNamePrefix={"amenitiespopup_select select-opt"}
          //onChange={(e) => {refine(e.value, e.label)}}
          onChange={(e) => setFurnishType(e.value)}
          isSearchable={false}
          styles={customStyles}
          components={{ DropdownIndicator: () => <DownIndicator /> }}
        />
      </div>
    </Form.Group>
    // : null
  )
}

const CustomMenuFurnishing = connectMenu(FurnishingMenuSelect);
// Property Furnishing

// Property amenities slider
function SampleNextArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className + ' slider-right-bg d-flex align-items-center'}
      onClick={onClick}
    >
      <i className="icon icon-right-arrow"></i>
    </div>
  );
}

function SamplePrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className + ' slider-left-bg d-flex align-items-center'}
      onClick={onClick}
    >
      <i className="icon icon-left-arrow"></i>
    </div>
  );
}

const AmenitiesSliderSettings = {
  dots: false,
  infinite: false,
  slidesToShow: 5,
  slidesToScroll: 1,
  autoplay: true,
  speed: 500,
  autoplaySpeed: 5000,
  pauseOnHover: true,
  nextArrow: <SampleNextArrow />,
  prevArrow: <SamplePrevArrow />,
  responsive: [
    {
      breakpoint: 1440,
      settings: {
        slidesToShow: 4,
        slidesToScroll: 1,
       
      }
    },
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 1,
     
      }
    },
    {
      breakpoint: 767,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        initialSlide: 1
      }
    }
  ]
};

// const AmenitiesSlider = ({ items, currentRefinement, refine }) => (
//   items && (items.length > 0) ?
//   <Row>
//     <Col>
//       <div className="amenities-slide">
//         <Slider {...AmenitiesSliderSettings}>
//             {items.map(item => (
//               <div key={item.value} className={ item.isRefined ? 'amenities_wrapper active' : 'amenities_wrapper' }>
//                 <a href="#"
//                   onClick={event => {
//                     event.preventDefault();
//                     refine(item.value);
//accodamationSummary.push(item.label)
//setAccodamationSummary(accodamationSummary)
//                   }}
//                 >
//                   <div className="">
//                     <ul className="list-inline d-flex align-items-center">
//                       <li className="list-inline-item mr-0">
//                         <figure>
//                           <img 
//                             src={
//                                   item.label === "covered-parking" ? parkingImg : 
//                                   item.label === "balcony" ? balconyImg :
//                                   item.label === "built-in-kitchen-appliances" ? kitchenImg :
//                                   item.label === "concierge-service" ? conciergeServiceImg :
//                                   item.label === "built-in-wardrobes" ? wardrobesImg :
//                                   item.label === "security" ? securityImg :
//                                   item.label === "central-a-c-heating" ? acHeatingImg :
//                                   item.label === "shared-gym" ? gymImg :
//                                   item.label === "shared-pool" ? sharedPoolImg :
//                                   item.label === "view-of-water" ? viewOfWaterImg :
//                                   propertyImg03
//                                 } 
//                           />
//                           <span className="property-count">{item.count}</span>
//                         </figure>
//                       </li>
//                       <li className="list-inline-item amenities_content">
//                         <p>Properties with <br /><strong>{capitalize(item.label === "central-a-c-heating" ? 'Central A/C & Heating' : item.label.replace(/-/g,' '))}</strong></p>
//                       </li>
//                     </ul>
//                   </div>
//                 </a>
//               </div>
//             ))}
//         </Slider>
//       </div>
//     </Col>
//   </Row>
//   : null
// );

// const CustomAmenitiesSlider = connectMenu(AmenitiesSlider);

const AmenitiesSlider = connectRefinementList(({ items, refine, accodamationSummary, setAccodamationSummary2, amenitesList }) => {
// console.log('itemsitems', items) 
if(accodamationSummaryList.length == 0){
  accodamationSummaryList = items
}
  return(
    amenitesList && (amenitesList.length > 0) ?
  <Row>
     <Col>
       <div className="amenities-slide">
         <Slider {...AmenitiesSliderSettings}>
            {amenitesList.map(item => (
              // <div key={item.value} className={ item.isRefined ? 'amenities_wrapper active' : 'amenities_wrapper' }>
              <div key={item.value} id={item.label} className={`${accodamationSummary} ${accodamationSummary.includes(item.label) ? 'amenities_wrapper active' : 'amenities_wrapper'}`}>
                <a href="javascript:void(0)"
                  // onClick={event => {
                  //   event.preventDefault();
                  //   refine(item.value);
                  //   accodamationSummary.push(item.label)
                  //   setAccodamationSummary(accodamationSummary)
                  // }}
                  onClick={(e)=>{
                    e.preventDefault();
                    //accodamationSummary.push(item.label)
                    setAccodamationSummary2(item.label)
                  }}
                >
                  <div className="">
                    <ul className="list-inline d-flex align-items-center">
                      <li className="list-inline-item mr-0">
                        <figure>
                          <img 
                            src={
                                  item.label === "covered-parking" ? parkingImg : 
                                  item.label === "balcony" ? balconyImg :
                                  item.label === "built-kitchen-appliances" ? kitchenImg :
                                  item.label === "concierge-service" ? conciergeServiceImg :
                                  item.label === "built-wardrobes" ? wardrobesImg :
                                  item.label === "security" ? securityImg :
                                  item.label === "central-a-c-heating" ? acHeatingImg :
                                  item.label === "shared-gym" ? gymImg :
                                  item.label === "shared-pool" ? sharedPoolImg :
                                  item.label === "view-of-water" ? viewOfWaterImg :
                                  propertyImg03
                                } 
                          />
                          <span className="property-count">                            
                            {items.filter(item2 => item2.label === item.label).length > 0 ?
                              items.filter(item2 => item2.label === item.label).map(item2 => parseInt(item2.count) > 0 ? item2.count : 0)
                              : 0
                            }
                          </span>
                        </figure>
                      </li>
                      <li className="list-inline-item amenities_content">
                        <p>Properties with <br />
                          <strong>
                            {
                              item.label === "central-a-c-heating" ? "Central A/C & Heating" 
                              : item.label === "built-kitchen-appliances" ? "Built in Kitchen Appliances"
                              : item.label === "built-wardrobes" ? "Built in Wardrobes"
                              : item.label === "walk-closet" ? "Walk in Closet"
                              : item.label === "dining-building" ? "Dining in Building"
                              : item.label === "retail-building" ? "Retail in Building"
                              : capitalize(item.label.replace(/-/g,' '))
                            }
                          </strong>
                        </p>
                      </li>
                    </ul>
                  </div>
                </a>
              </div>
            ))}
        </Slider>
      </div>
    </Col>
  </Row>
  : null
)}
);

// Property amenities slider

// custom refinementlist
const CustomRefinementList = connectRefinementList(({ items, refine, accodamationSummary, setAccodamationSummary, amenitesList }) => {
  if(accodamationSummaryList.length == 0){
    accodamationSummaryList = items
  }
  accodamationSummaryList.map((item)=>{
    if(item.isRefined){ 
      accodamationSummary.push(item.label)
    } 
  })

  amenitesList.sort((a,b) => (a.label > b.label) ? 1 : ((b.label > a.label) ? -1 : 0));

  return (
    <div className="amenities-refine">
      {
        amenitesList.length > 0 ? <div className="refine-heading">Amenities</div> : ''
      }
      <ul className="refine-search-left">
        {amenitesList.map(item => ( 
          <li key={item.label}>
            <label className="refine-check-wrapper">
              {
                item.label === "officestudy-room" ? "Central A/C & Heating"
                : item.label === "built-kitchen-appliances" ? "Built in Kitchen Appliances"
                : item.label === "built-wardrobes" ? "Built in Wardrobes"
                : item.label === "walk-closet" ? "Walk in Closet"
                : item.label === "dining-building" ? "Dining in Building"
                : item.label === "retail-building" ? "Retail in Building"
                : capitalize(item.label.replace(/-/g,' '))
              }
              {/* <input type="checkbox" checked={item.isRefined} onChange={() => refine(item.value)} /> */}
              <input type="checkbox" checked={accodamationSummary.includes(item.label)} onChange={(e)=>{
                if(e.target.checked){
                  //item.isRefineNotChecked = false
                  accodamationSummary.push(item.label)
                }else{
                  //item.isRefined = false;  
                  accodamationSummary = accodamationSummary.filter(e => e !== item.label)
                }
                setAccodamationSummary(accodamationSummary)
                // refine(item.value) 
              }} />
              <span className="refine-checkmark"></span>
            </label>
          </li>
        ))}
      </ul>
    </div>
  );
})

// custom refinementlist

// Create URL
export const createURL = (props, state) => {

  //console.log("createURL", state);

  let myarea = state.menu

  const isDefaultRoute =
    !state.query &&
    !state.SortBy &&
    state.page === 1 &&
    state.menu &&
    state.range &&
    !state.range.price &&
    !state.range.size &&
    !state.range.bedroom &&
    (state.refinementList && !state.refinementList.accommodation_summary && !state.refinementList.accommodation_summary.length === 0)


  if (isDefaultRoute) {
    return ""
  }


  var areaPath = "in-dubai/"
  var bedroomPath = ""
  var pricePath = ""
  var pagePath = ""
  var propertypath = ""
  var sortPath = ""
  var amenitiesPath = ""
  var saleTypePath = ""
  var furnishPath = ""
  var sizePath = ""

  if (state.query) {
    if (state.query) {
      areaPath = "in-" + encodeURIComponent(decodeURIComponent(state.query)) + "/"
    }
  }

  const queryParameters = {}

  //console.log("propertypath 1",  state);

  // Property path
  if (state.menu) {
    if (myarea["seobuilding"]) {
      propertypath = "type-" + myarea["seobuilding"] + "/"
    }

    // Sale type path
    if (myarea["sublisting_type"]) {
      saleTypePath = "sale-type-" + myarea["sublisting_type"] + "/"
    }
    // Sale type path

    // furnish path
    if (myarea["furnished"]) {
      furnishPath = "furnished-" + myarea["furnished"] + "/"
    }
    // furnish path
  }
  // Property path

  //console.log("propertypath 2",  state.menu);

  // Bedrooms & Price range path
  if (state.range) {
    // Bedrooms
    if (state.range.hasOwnProperty('bedroom') && state.range.bedroom.max === 0) {
      bedroomPath = "studio/" //queryParameters.price_max = state.range.price.max;
    }
    if (state.range.bedroom) {
      if (state.range.bedroom.min) {
        if (state.range.bedroom.min === "0") {
          bedroomPath = "studio/" //queryParameters.price_max = state.range.price.max;
        } else {
          bedroomPath = state.range.bedroom.min + "-and-more-bedrooms/" //queryParameters.price_max = state.range.price.max;
        }
      }
      // if (state.range.bedroom.min && state.range.bedroom.max) {
      //   bedroomPath = state.range.bedroom.min + "-to-" + state.range.bedroom.max + '-bedrooms/' //queryParameters.price_min = state.range.price.min;
      // } else if (state.range.bedroom.max) {
      //   bedroomPath = "upto-" + state.range.bedroom.max + '-bedrooms/' //queryParameters.price_max = state.range.price.max;
      // } else if (state.range.bedroom.min) {
      //   bedroomPath = state.range.bedroom.min + '-and-more-bedrooms/' //queryParameters.price_max = state.range.price.max;
      // }
    }

    // Price
    if (state.range.price) {
      if (state.range.price.min && state.range.price.max) {
        pricePath = "between-" + state.range.price.min + "-and-" + state.range.price.max + '/' //queryParameters.price_min = state.range.price.min;
      } else if (state.range.price.max) {
        pricePath = "under-" + state.range.price.max + '/' //queryParameters.price_max = state.range.price.max;
      } else if (state.range.price.min) {
        pricePath = "over-" + state.range.price.min + '/' //queryParameters.price_max = state.range.price.max;
      }
    }

    // Size
    if (state.range.size) {
      if (state.range.size.min && state.range.size.max) {
        sizePath = "size-" + state.range.size.min + "-to-" + state.range.size.max + '/' //queryParameters.price_min = state.range.price.min;
      } else if (state.range.size.max) {
        sizePath = "below-" + state.range.size.max + '/' //queryParameters.price_max = state.range.price.max;
      } else if (state.range.size.min && parseInt(state.range.size.min) > 0) {
        sizePath = "above-" + state.range.size.min + '/' //queryParameters.price_max = state.range.price.max;
      }
    }
  }
  // Bedrooms & Price range path

  // Sort path
  if (state.sortBy) {
    if (state.sortBy === index_name + "_price_asc") {
      sortPath = "sortby-price-asc/";
    }
    if (state.sortBy === index_name + "_price_desc") {
      sortPath = "sortby-price-desc/";
    }
    if (state.sortBy === index_name + "_most_recent") {
      sortPath = "sortby-most-recent/";
    }
  }
  // Sort path

  // Page path
  if (state.page && state.page !== 1) {
    pagePath = `page-${state.page}/`
  }
  // Page path

  // Amenities path
  if (state.refinementList) {
    //console.log("accommodation_summary==>", typeof state.refinementList.accommodation_summary, state.refinementList.accommodation_summary);

    if (typeof state.refinementList.accommodation_summary === "object") {
      if (state.refinementList.accommodation_summary.length > 0) {
        var accommodation_summary_list = state.refinementList.accommodation_summary.filter(function (item, index, inputArray) {
          return inputArray.indexOf(item) == index;
        });
        if (accommodation_summary_list.length > 0) {
          amenitiesPath = `with-${accommodation_summary_list.map(encodeURIComponent).join('-and-')}/`
        }
      }
    } else {
      amenitiesPath = `with-${state.refinementList.accommodation_summary}/`
    }

  }
  // Amenities path

  const queryString = qs.stringify(queryParameters, {
    addQueryPrefix: true,
    arrayFormat: "repeat",
  })

  let myUrl = `${areaPath}${saleTypePath}${propertypath}${bedroomPath}${pricePath}${amenitiesPath}${sizePath}${furnishPath}${queryString}${pagePath}${sortPath}`

  //console.log("myUrl", myUrl);

  if ("/" === myUrl.substr(myUrl.length - 1))
    myUrl = myUrl.substr(0, myUrl.length - 1)

  if (!myUrl.endsWith('/'))
    myUrl = myUrl + '/';

  myUrl = myUrl.toLowerCase();

  return `/properties/for-sale/${myUrl}`
}
// Create URL

// URL to searchstate
const searchStateToUrl = (props, searchState) =>
  searchState ? `${createURL(props, searchState)}` : ""

const urlToSearchState = location => {
  if (location) {
    var query = ""
    let pathUri_main = location.pathname.split("/properties/for-sale")

    var areaVal = ""
    var bedVal = 21
    var minpriceVal = ""
    var maxpriceVal = ""
    var minbedroomval = ""
    var maxbedroomval = ""
    var pageVal = ""
    var propertyval = ""
    var sortVal = ""
    var sortVal_filt = ""
    var amenitiesVal = ""
    var saleTypeVal = ""
    var minsizeval = ""
    var maxsizeval = ""
    var furnishVal = ""

    if (pathUri_main[1]) {
      // following could be regexp
      let pathUri = pathUri_main[1].split("/")

      //lets loop
      for (let vi = 1; vi <= pathUri.length; vi++) {
        // check for area
        if (typeof pathUri[vi] === "undefined") {
          continue
        }

        // Sale type
        if (pathUri[vi].indexOf("sale-type-") == 0) {
          saleTypeVal = pathUri[vi].replace("sale-type-", "")
        }

        // Property type
        if (pathUri[vi].indexOf("type-") == 0) {
          propertyval = pathUri[vi].replace("type-", "")
        }

        // Area
        if (pathUri[vi].indexOf("in-") == 0) {
          areaVal = pathUri[vi].replace("in-", "")
        }

        // Price
        if (
          pathUri[vi].indexOf("between-") >= 0 ||
          pathUri[vi].indexOf("over-") >= 0 ||
          pathUri[vi].indexOf("under-") >= 0
        ) {
          let priceFilt1 = pathUri[vi].split("over-")
          if (priceFilt1[1]) {
            minpriceVal = priceFilt1[1]
          }
          let priceFilt2 = pathUri[vi].split("under-")
          if (priceFilt2[1]) {
            maxpriceVal = priceFilt2[1]
          }
          let priceFilt3 = pathUri[vi].split("between-")
          if (priceFilt3[1]) {
            let priceFilt4 = priceFilt3[1].split("-and-")
            minpriceVal = priceFilt4[0]
            maxpriceVal = priceFilt4[1]
          }
        }

        // furnished type
        if (pathUri[vi].indexOf("furnished-") == 0) {
          furnishVal = pathUri[vi].replace("furnished-", "")
        }

        // Size
        if (
          pathUri[vi].indexOf("size-") >= 0 ||
          pathUri[vi].indexOf("above-") >= 0 ||
          pathUri[vi].indexOf("below-") >= 0
        ) {
          let priceFilt1 = pathUri[vi].split("above-")
          if (priceFilt1[1]) {
            minsizeval = priceFilt1[1]
          }
          let priceFilt2 = pathUri[vi].split("below-")
          if (priceFilt2[1]) {
            maxsizeval = priceFilt2[1]
          }
          let priceFilt3 = pathUri[vi].split("size-")
          if (priceFilt3[1]) {
            let priceFilt4 = priceFilt3[1].split("-to-")
            minsizeval = priceFilt4[0]
            maxsizeval = priceFilt4[1]
          }
        }

        // Bedrooms
        if (pathUri[vi].indexOf("-and-more-") > 0) {
          bedVal = pathUri[vi].replace("-and-more-bedrooms", "")
          sortVal = index_name + "_bedrooms"
        }

        if (pathUri[vi].indexOf("studio") === 0) {
          // its studio bedroom
          bedVal = 0
        }

        // if (pathUri[vi].indexOf("upto-") >= 0) {
        //   maxbedroomval = pathUri[vi].replace("upto-", "")
        // }

        // if (pathUri[vi].indexOf("-to-") >= 0) {
        //   let pathTo_1 = pathUri[vi].split("-to-");
        //   let parthTo_2 = pathTo_1[1].split("-bedrooms");

        //   minbedroomval = pathTo_1[0];
        //   maxbedroomval = parthTo_2[0];
        // }

        // Sort by
        if (pathUri[vi].indexOf("sortby-") >= 0) {
          sortVal_filt = pathUri[vi].replace("sortby-", "")

          //sortVal = index_name;

          if (sortVal_filt === "price-asc") {
            sortVal = index_name + "_price_asc"
          }

          if (sortVal_filt === "price-desc") {
            sortVal = index_name + "_price_desc"
          }

          if (sortVal_filt === "most-recent") {
            sortVal = index_name + "_most_recent"
          }
        }

        // Amenities
        if (pathUri[vi].indexOf("with-") >= 0) {
          // its Amenities
          var amenitiesArrfilt = pathUri[vi].replace("with-", "")
          amenitiesVal = amenitiesArrfilt.split("-and-")
        }

        // Page
        if (pathUri[vi].indexOf("page") >= 0) {
          pageVal = pathUri[vi].replace("page-", "")
        }
      }
    }
  }

  // Sale type
  if (saleTypeVal) {

    if ('primary-market' == saleTypeVal) {
      saleTypeVal = "Primary Market"
    }
    if ('off-plan' == saleTypeVal) {
      saleTypeVal = "Off-Plan"
    }
    if ('lease-to-own' == saleTypeVal) {
      saleTypeVal = "Lease to Own"
    }

    query += `&menu[sublisting_type]=` + saleTypeVal
  }

  // Furnished type
  if (furnishVal) {
    query += `&menu[furnished]=` + furnishVal
  }

  // Size
  if (minsizeval) {
    query += `&range[size][min]=` + minsizeval
  }
  if (maxsizeval) {
    query += `&range[size][max]=` + maxsizeval
  }

  // Property type
  if (propertyval) {
    query += `&menu[seobuilding]=` + propertyval
  }

  // Area
  if (areaVal) {
    areaVal = areaVal.toLowerCase();
    if (areaVal == "dubai") query += `&query=`
    else query += `&query=` + areaVal
  }

  // Price
  if (minpriceVal) {
    query += `&range[price][min]=` + minpriceVal
  }

  if (maxpriceVal) {
    query += `&range[price][max]=` + maxpriceVal
  }



  // Bedrooms
  // if (minbedroomval) {
  //   query += `&range[bedroom][min]=` + minbedroomval
  // }

  // if (maxbedroomval) {
  //   query += `&range[bedroom][max]=` + maxbedroomval
  // }
  if (bedVal === 0) {
    query += `&range[bedroom][max]=` + 0
    query += `&range[bedroom][min]=` + 0
  } else if (bedVal < 21) {
    query += `&range[bedroom][min]=` + bedVal
  }

  // Amenities
  if (amenitiesVal) {
    for (let v = 0; v <= amenitiesVal.length; v++) {
      if (typeof amenitiesVal[v] !== "undefined") {
        query += `&refinementList[accommodation_summary]=` + amenitiesVal[v]
      }
    }
  }

  // Page
  if (pageVal) {
    query += `&page=${pageVal}`
  }

  // Sort by
  if (sortVal) {
    query += `&sortBy=` + sortVal
  }

  return qs.parse(query)
}
// URL to searchstate

// Active filter onscroll
let lastScrollY = 0
let activeScroll = ""
// Active filter onscroll

// Include sold
const ToggletttRefinement = ({
  currentRefinement,
  label,
  count,
  refine,
  createURL,
}) => {
  return (
    <a
      href={'#'}
      onClick={event => {
        event.preventDefault();
        includeSold = !includeSold;
        refine(!currentRefinement);
      }}
    >
      <div className="switch-button-include">
        <label for="toggle-button" className="include_switch">{label}
          <input type="checkbox" id="toggle-button" checked={includeSold ? "checked" : ""} />
          <span className="toggle-switch"></span>
        </label>
      </div>
    </a>
  )
}

const CustomToggleRefinement = connectToggleRefinement(ToggletttRefinement);
// Include sold

class PropertySales extends React.Component {

  constructor(props) {
    super(props);
  }

  // 
  state = {
    searchState: urlToSearchState(this.props.location),
    refinementList: {
      accommodation_summary: []
    },
    menu: {
      furnished: ""
    },
    range: {
      size: {
        min: "",
        max: ""
      }
    },
    include_sold: true,
    areaOptions:[],
    amenitesOptions:[]
  }

  componentDidUpdate(prevProps) {
    if (prevProps.location !== this.props.location) {
      this.setState({ searchState: urlToSearchState(this.props.location) })
    }
  }

  onSearchStateChange = searchState => {
    clearTimeout(this.debouncedSetState)
    this.debouncedSetState = setTimeout(() => {
      navigate(searchStateToUrl(this.props, searchState), searchState)
    }, updateAfter)
    this.setState({ searchState })

  }

  // onSearchStateChange2 = (searchState) => {      
  //   this.setState({ searchState })
  // }
  // 

  componentDidMount() {
    // Filter active onscroll
    window.addEventListener("scroll", this.handleScroll, true)
    // Filter active onscroll

    // Sold filter
    // $( ".filtersearchsold" ).insertAfter( ".defaultsoldfilter" );
    // Sold filter

    // load on top
    $(document).ready(function () {
      $("html, body").scrollTop(0);
    });
    // load on top
    this.getAreas()
    this.getAmenites()
  }

  // Filter active onscroll
  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleScroll)
  }

  // Amenities filter
  setAccodamationSummary = (val) => {
    //console.log("val", val);

    //console.log("propertypath state 1", val)
    if (typeof val === "object") {
      val = val.filter(function (item, index, inputArray) {
        return inputArray.indexOf(item) == index;
      });
    }

    // this.setState(prevState =>({
    //   searchState:{
    //     ...prevState.searchState, 
    //     refinementList:{
    //       accommodation_summary: val
    //     }
    //   }
    // }));

    this.setState({
      refinementList: {
        accommodation_summary: val
      }
    })

    // this.navigateSearchUrl();
  }

  includeSoldClick = () => {
    this.setState({ include_sold: !this.state.include_sold });
  }

  getAreas = async () => { 
    try {
        const { data } = await axios.get(`${process.env.GATSBY_STRAPI_SRC}/json/areas.json`, {
            headers: {
            Authorization: `Bearer ${process.env.GATSBY_STRAPI_FORM_TOKEN}`,
            }})
    
        let options = []
        if(data && data.length > 0){
          data.map((op)=>{
            options.push({ label: op.name, value: op.slug})
          })
        
        }
        if(this.state.areaOptions.length == 0){
          this.setState({areaOptions: options}) 
        }
       
        // return options;
    } catch (error) {
        // console.error(error)
    }
  }
  getAmenites = async (state, setState) => {  
    try {
        const { data } = await axios.get(`${process.env.GATSBY_STRAPI_SRC}/amenities.json`, {
            headers: {
            Authorization: `Bearer ${process.env.GATSBY_STRAPI_FORM_TOKEN}`,
            }})
    
        let options = [] 
        if(data['residential'] && data['residential'].length > 0){
          data['residential'].map((op)=>{
            options.push({ label: op.value, value: op.value})
          })
        
        }
        if(this.state.amenitesOptions.length == 0){
          this.setState({amenitesOptions: options}) 
        } 
        // return options;
    } catch (error) {
        // console.error(error)
    }
  }

  // Furnish Type filter
  setFurnishType = (val) => {
    this.setState({
      menu: {
        ...this.state.menu.furnished, furnished: val
      }
    })
    // this.navigateSearchUrl();
  }

  // Min size filter
  setMinRange = (val) => {
    this.setState({
      range: {
        size: { ...this.state.range.size, min: val }
      }
    });
  }

  // Max size filter
  setMaxRange = (val) => {
    this.setState({
      range: {
        size: { ...this.state.range.size, max: val }
      }
    })
  }

  setAccodamationSummary2 = (val) => {

    //console.log("propertypath state 2", val)

    this.setState(prevState => ({
      searchState: {
        ...prevState.searchState,
        refinementList: {
          accommodation_summary: [val]
        }
      }
    }), this.navigateSearchUrl);

    this.setState({
      refinementList: {
        accommodation_summary: [val]
      }
    })

  }

  navigateSearchUrl = () => {
    //console.log("propertypath state 3", this.state)
    var state_update = this.state.searchState;
    navigate(createURL(this.props, state_update))
  }
  // Amenities filter



  handleScroll = () => {
    lastScrollY = window.scrollY

    if (lastScrollY > 113) {
      activeScroll = "active-scroll"
    } else if (lastScrollY < 113) {
      activeScroll = ""
    }

    this.setState({ activeScroll })
  }
  // Filter active onscroll

  // Sales & Rent dropdown
  onChangeSellRent = (e) => {
    navigate(`/${e.value}`)
  }
  // Sales & Rent dropdown

  // Mobile filter results
  mobileFilter = (e) => {
    $(".filter-results-dropdown").toggleClass("active-result")
  }
  // Mobile filter results

  // Clear filter
  clearFilter = (e) => {
    navigate(`/properties/for-sale/in-dubai/`)
  }
  // Clear filter

  // Advanced filter
  advancedFilter = (e) => {
    $(".filter-search-overlay").addClass("active-filter")
    $("html").addClass("active-filter")
  }

  advancedFilterClose = (e) => {
    $(".filter-search-overlay").removeClass("active-filter")
    $("html").removeClass("active-filter")
  }

  resetFilter = (e) => {
    $(".filter-search-overlay").removeClass("active-filter")
    $("html").removeClass("active-filter")
    navigate(`/properties/for-sale/in-dubai/`)
  }
  // Advanced filter

  // Search filter text
  searchFilterText = (e) => {
    $(".search_text").toggleClass("active")
    $(".result-header").toggleClass("searchText")
  }
  // Search filter text

  // Map view
  mapView = (e) => {
    // $(".mapview").addClass("active")
    // $(".gridview").removeClass("active")

    // $(".result-grid").addClass("active")
    // $(".mapresults").removeClass("active")
    // navigate(`/properties-map/for-sale/in-dubai`)
    // console.log(this.props.location.pathname)

    // navigate(this.props.location.pathname.replace("properties", "properties-map"))
    window.location = this.props.location.pathname.replace("properties", "properties-map")
  }

  // gridView = (e) => {
  //   $(".mapview").removeClass("active")
  //   $(".gridview").addClass("active")

  //   $(".result-grid").removeClass("active")
  //   $(".mapresults").addClass("active")
  // }
  // Map View



  render() {
    const searchParams = parseSearchUrl("sales", this.props.location.pathname);
    const h1 = propertyH1(searchParams);
    const areaName = searchParams.areas;
    const pType = searchParams.pType;
    const bType = searchParams?.type
    const capitalizedbType =
      bType.charAt(0).toUpperCase()
      + bType.slice(1)
    const buildingType = capitalizedbType == "" ? "Property" : capitalizedbType
    // console.log("paramssss",searchParams)


    const isPageLoadBg = (typeof this.props.path === "undefined");

    var validatetext = /^[a-zA-Z]+$/;
    let areaPropertyName;
    let search_area = "";
    let rawtext = areaName.replace(/ /g, '');
    if (rawtext.match(validatetext)) {
      if (areaName != "dubai") {
        search_area = areaName.replace("-", " ");
      }
    }
    if (h1) {
      areaPropertyName = h1.replace("Property", 'Properties').replace("covered-parking", "Covered parking").replace("built-wardrobes", "Built in Wardrobes").replace("central-a-c-heating", "Central A/C & Heating").replace("concierge-service", "Concierge service").replace("security", "Security").replace("built-kitchen-appliances", "Built in Kitchen Appliances").replace("balcony", "Balcony").replace("shared-gym", "Shared gym").replace("shared-pool", "Shared pool").replace("shared-spa", "Shared spa").replace("view-of-water", "View of water").replace("pets-allowed", "Pets allowed").replace("walk-closet", "Walk in Closet").replace("maids-room", "Maids room").replace("sea-view", "Sea view").replace("private-pool", "Private pool").replace("private-garden", "Private garden").replace("study", "Study").replace("available-furnished", "Available furnished").replace("backyards", "Backyards").replace("conference-room", "Conference room").replace("maid-service", "Maid service").replace("retail-building", "Retail in Building").replace("rooftop-terrace", "Rooftop terrace").replace("terrace-garden", "Terrace garden").replace("view-of-landmark", "View of landmark").replace(/-/g, ' ');
    } else {
      areaPropertyName = h1.replace(/-/g, ' ');
    }

    //console.log("include_sold", this.state.include_sold);


    var mystatus = "";

    if (this.state.include_sold) {
      mystatus = '(status:"For Sale" OR status:"sold")'
    } else {
      mystatus = 'status:"For Sale"'
    }

    var schematitle = ""
    if (h1) {
      schematitle = h1.replace("Property", 'Properties').replace(/\s\s+/g, ' ')
    }

    const metaDescription = generateMetaDescription(searchParams);
    const introCopy = generateIntroCopy(searchParams);
    
    return (
      <>
        {!isPageLoadBg &&
          <SeoResults title={this?.props?.pageContext?.title ? this?.props?.pageContext?.title : h1} description={metaDescription} searchParams={searchParams} location={this.props.location} />
          // <SEO title={this?.props?.pageContext?.title ? this?.props?.pageContext?.title : h1} description={this?.props?.pageContext?.desc} />
        }

        <div className={"wrapper"}>
          <Header Layout={"Without_Banner"} />
          <SearchResultSchema title={schematitle} lowPrice={500000} highPrice={15000000} page="searchsale" />


          <div className="page-content">
            {/* <div className="filtersearchsold">
                          <IncludeSold searchState={this.state.searchState}
                            searchClient={searchClient}
                            indexName={index_name}
                            createURL={createURL}
                            onSearchStateChange={this.onSearchStateChange2}
                          />
                        </div> */}
            <InstantSearch
              indexName={index_name}
              searchClient={searchClient}
              searchState={this.state.searchState}
              onSearchStateChange={this.onSearchStateChange}
              createURL={createURL}
              routing="true"
            >
              <Configure
                hitsPerPage={20}
                filters={`publish:true AND search_type:sales AND department:residential AND ${mystatus}`}
              />
              <div className="d-none">
                {/* <RefinementList
                                    attribute="search_type"
                                    defaultRefinement={["sales"]}
                                />
                                <RefinementList
                                    attribute="department"
                                    defaultRefinement={["residential"]}
                                />
                                <RefinementList
                                    attribute="status"
                                    defaultRefinement={["for sale"]}
                                />
                                <RefinementList
                                    attribute="publish"
                                    defaultRefinement={["true"]}
                                /> */}

                {/* {search_area.length>0 ?
                                <RefinementList
                                  attribute="address_summary"
                                  defaultRefinement={[search_area]}
                                /> : ''
                                }    */}
              </div>

              <div className={activeScroll + " result-filter"}>
                <Container fluid >
                  <div className={"h6 d-flex d-xl-none justify-content-between align-items-center filter-results-dropdown"} onClick={this.mobileFilter}><span>Filter Results</span> <i className="arrow-icon"></i></div>
                  <Form className="result-search d-xl-flex justify-content-between align-items-center">
                    <SearchBox areaOptions={this.state.areaOptions} />

                    <Form.Group>
                      {/* <Form.Label>Status</Form.Label> */}
                      <div className="select-group">
                        <Select
                          options={[
                            { value: 'properties/for-sale/in-dubai/', label: 'For Sale' },
                            { value: 'properties/sold/in-dubai/', label: 'Sold' },
                            { value: 'properties/for-rent/in-dubai/', label: 'For Rent' },
                            { value: 'properties/let/in-dubai/', label: 'Let' }
                          ]}
                          defaultValue={{ value: 'sales', label: 'For Sale' }}
                          classNamePrefix={"select-opt"}
                          placeholder="Property Status"
                          onChange={this.onChangeSellRent}
                          isSearchable={false}
                        />
                      </div>
                    </Form.Group>

                    <CustomMenuSaleType attribute="sublisting_type" />

                    <CustomminpriceSelect attribute="price" />

                    <CustommaxpriceSelect attribute="price" />

                    <CustombuildingSelectbox attribute="seobuilding" />

                    <CustomminbedroomSelect attribute="bedroom" />

                    {/* <CustommaxbedroomSelect attribute="bedroom" /> */}

                    {/* <CustomMenuSelect attribute="accommodation_summary" /> */}
                    <a href="/properties/for-sale/in-dubai/" className="reset-filter d-xl-block d-none">Reset</a>
                    <a href="javascript:void(0)" className="reset-filter d-flex align-items-center" onClick={this.advancedFilter}><i className="search-filter-icon mr-3"></i> Advanced</a>
                  </Form>
                </Container>

                <section className="filter-search-overlay">
                  <div className="filter-close">
                    <a href="javascript:void(0)" onClick={this.advancedFilterClose}><i className="search-filter-close"></i></a>
                  </div>

                  <div className="filter-wrapper">
                    <Container>
                      <Row className="justify-content-center">
                        <Col xl={6} lg={8} md={10}>
                          <div className="h3">Advanced Filters</div>
                          <CustomRefinementList
                            attribute="accommodation_summary"
                            limit={100}
                            operator={"and"}
                            accodamationSummary={this.state.refinementList.accommodation_summary}
                            setAccodamationSummary={this.setAccodamationSummary}
                            amenitesList={this.state.amenitesOptions}
                          />
                          {/* <div className="refine-line"></div> */}
                          <div className="amenities-property-size mt-5">
                            <div className="refine-heading">Property Size</div>
                            <ul className="list-inline d-flex justify-content-between">
                              <li className="list-inline-item">
                                <CustomminsqftSelect
                                  attribute="size"
                                  min_size={this.state.range.size.min}
                                  max_size={this.state.range.size.max}
                                  setMinRange={this.setMinRange}
                                />
                              </li>
                              <li className="list-inline-item">
                                <CustommaxsqftSelect
                                  attribute="size"
                                  min_size={this.state.range.size.min}
                                  max_size={this.state.range.size.max}
                                  setMaxRange={this.setMaxRange}
                                />
                              </li>
                            </ul>
                          </div>
                          {/* <div className="refine-line"></div> */}
                          <div className="amenities-furnishing mt-5">
                            <div className="refine-heading">Furnishing</div>
                            <CustomMenuFurnishing
                              attribute="furnished"
                              furnish_type={this.state.menu.furnished}
                              setFurnishType={this.setFurnishType}
                            />
                          </div>
                          <div className="refine-buttons">
                            <div className="d-flex justify-content-between">
                              <a href="javascript:void(0)" onClick={() => {

                                navigate(createURL(this.props, this.state))
                                this.advancedFilterClose()

                                // CustomRefinementList({attribute:"accommodation_summary", limit: 1000, applyFilter: true})
                                //    <CustomRefinementList
                                //    attribute="accommodation_summary"
                                //    limit={100}
                                //    applyFilter={true}
                                //  />
                                //this.advancedFilterClose
                              }} className="btn btn-primary"><span>Update Results</span></a>

                              <a href="/properties/for-sale/in-dubai" className="btn btn-outline btn-outline--white"><span>Reset</span></a>
                            </div>
                          </div>
                        </Col>
                      </Row>
                    </Container>
                  </div>
                </section>
              </div>

              <div className="result-list pb-0">
                <Container>
                  {/* <CustomAmenitiesSlider 
                                  attribute="accommodation_summary" 
                                  limit={100}
                                /> */}
                  <AmenitiesSlider
                    attribute="accommodation_summary"
                    limit={100}
                    accodamationSummary={this.state.refinementList.accommodation_summary}
                    setAccodamationSummary2={this.setAccodamationSummary2}
                    amenitesList={this.state.amenitesOptions}
                  />
                  <Row>
                    <Col>

                      <div className="result-header d-md-flex justify-content-between align-items-start">
                        <h1 className="total-result">{areaPropertyName} (<CustomStats />) <a href="javascript:void(0)" onClick={this.searchFilterText}><i className="info-icon"></i></a></h1>
                        <Form className="control-list d-md-flex align-items-center">
                          <div className="property-filter_check">
                            {/* <ToggleRefinement
                                                    attribute="status"
                                                    label="Include Sold / SSTC ddd"
                                                    value={['sold']}
                                                /> */}

                            {/* <CustomToggleRefinement
                                                  attribute="status"
                                                  label="Include Sold"
                                                  value={['Sold']}
                                                  defaultRefinement={includeSold}
                                                /> */}

                            <div className="switch-button-include">
                              <label for="toggle-button" className="include_switch">    Include Sold
                                <input type="checkbox" id="toggle-button" onChange={this.includeSoldClick} />
                                <span className="toggle-switch"></span>
                              </label>
                            </div>

                          </div>
                          <Form.Group className="d-none d-md-block">
                            <div>
                              <i className="sort-icon"></i>
                              <span className="d-none d-lg-inline-block">
                                <div className="d-flex align-items-center">
                                  <SortBy
                                    defaultRefinement={index_name}
                                    className="search-sory-by"
                                    items={[
                                      // { value: index_name+"_most_recent", label: "Newly Listed" },
                                      { value: index_name, label: "Newly Listed" },
                                      { value: index_name + "_price_desc", label: "Highest Price" },
                                      { value: index_name + "_price_asc", label: "Lowest Price" },
                                    ]}
                                  />
                                  {/* <i className="arrow-icon"></i> */}
                                </div>
                              </span>
                            </div>
                          </Form.Group>
                          <Form.Group>
                            <div onClick={this.mapView} className="map-icon-wrapper mapview"><i className="map-icon"></i> Map <span className="d-md-none d-lg-inline-block">View</span></div>
                            {/* <div onClick={this.gridView} className="map-icon-wrapper gridview"><i className="grid-icon"></i> Grid <span className="d-md-none d-lg-inline-block">View</span></div> */}
                          </Form.Group>
                        </Form>
                      </div>

                    </Col>
                  </Row>
                  <Row>
                    <Col lg={12}>
                      <p className="search_text sale"> {introCopy ? HTMLParser(introCopy) : ''} </p>
                    </Col>
                  </Row>
                </Container>
              </div>
              <div className="result-list pt-0 result-grid">
                <Container>
                  <div className="result-cards">
                    <Row>
                      <CustomInfiniteHits location={this.props.location} />
                      <CustomNoStats />
                    </Row>
                  </div>
                </Container>
              </div>
              {/* <div className="mapresults active">
                              <MapResults />
                            </div> */}
            </InstantSearch>
          </div>
          {/* <PropertyFooter Action={'for-sale'} Area={searchParams.areas} Property_Type={searchParams.type} /> */}
          <Footer Search_Result={'Residential'} Action={'for-sale'} Area={searchParams.areas} MinBedrooms={searchParams.bedrooms} MaxBedrooms={searchParams.maxbedrooms} Property_Type={searchParams.type} />
        </div>
      </>
    )
  }
}

export default PropertySales