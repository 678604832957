import { Link } from "@StarberryUtils";
import React, { useState, useEffect } from "react";
import { Button, Form, Container, Row, Col } from 'react-bootstrap';

import { useLocation } from "@reach/router";

const PropertyFooter = (props) => {
const location = useLocation();
//console.log("Areaprops",props.Area);

    return (
      <Container>
        {/* {props.Action && props.Area === "the pearl" && props.Property_Type === "apartments" ? "Hello this is my first pearl property" : props.Action && props.Area === "lusail city" && props.Property_Type === "apartments" ? "Hello Lusail property" : props.Action && props.Area === "doha qatar" ? "Hello first doha qatar property" : 
        <div class="property-bottom-footer">
            <h4>Choosing the best apartments for sale in Qatar</h4>
            <p>You have set your thoughts on the potential of apartments for sale in Qatar, and what one, or perhaps several, could offer you! What do you envisage from the purchase? A high-quality, new apartment with low maintenance needs? Sipping a refreshing drink on a private balcony overlooking a dazzling Riviera? Perhaps a home within reach of business lounges, leisure hotspots and upscale restaurants? Or is it a real estate investment that encourages a growing rental yield?</p>
            <p>Despite economic dips experienced over the past, Qatar’s real estate market remains resilient and continues to develop with phenomenal architectural designs, sustainable innovations and eco-friendly aspects. Not to mention upcoming world-renowned sporting events in Qatar that will positively impact the market.</p>
            <p>Whatever your dream may be in Qatar, it is definitely possible with our local real estate experts who specialise in helping <a href="https://www.thepearlgates.com/qatar-property-services/buy/">buyers</a> choose the very best apartments for sale in Qatar or apartments for sale in Doha—Qatar’s shining jewel Capital that attracts expatriates in the thousands every year, thanks to the city’s progressive economic and infrastructural nature, and exponential career opportunities.</p>
            <h4>The creme de la creme of Gulf lifestyles for owners of apartments for sale in Qatar</h4>
            <p>A continual stream of new apartments for sale in Doha and across Qatar enter the market, along with beach-side villas, penthouses, stylish townhouses, duplexes and land. As you can imagine, all are built to an impeccable standard with a focus on convenience and spaciousness. They commonly include high-end detailing and contemporary fully-fitted kitchens.</p>
            <p>Qatar’s apartments are never far from daily necessities and services, be it a quick trip to the pharmacy, educational establishments held to high standards, or business premises in the hub of Doha city that sit central to major airports around the world.</p>
            <p>They are typically situated in emerging <a href="https://www.thepearlgates.com/living-in-dubai/">communities</a> with properties hitting the market right now, and continue to attract new and existing buyers from around the world and locals, too! These beautiful, new communities see increasing interest with our professional property consultants at <a href="https://www.thepearlgates.com/">The Pearl Gates</a> for above average ROIs and the high potential for growing property values. It’s true - there has never been a better time to buy apartments for sale in Doha and apartments for sale in Qatar than now.</p>
            <p>Up-and-coming areas gaining attention that we can help you explore the lifestyle and investment potential of.</p>
            <p>The Pearl | West Bay | West Bay Lagoon | Lusail City | Msheirab Downtown. </p>
            <h4>Locals, expats and investors foreign to Qatar alike are welcome to enjoy the benefits of apartments for sale in Qatar</h4>
            <p>Locals can enjoy access to all that Qatar’s property market offers. For buyers foreign to Qatar - whether resident expatriates or overseas investors - there are currently 10 freehold property purchasing areas with bountiful options for apartments for sale in and around Doha, Qatar.</p>
            <p>Freehold areas open to buyers foreign to Qatar:</p>
            <ul>
              <li>The Pearl, Qatar</li>
              <li>West Bay</li>
              <li>Al Khor Resort</li>
              <li>Raqdat Al Jahaniyah </li>
              <li>Al Wasail</li>
              <li>Al Khraji</li>
              <li>Al Qassar</li>
              <li>Al Dafna</li>
              <li>Jabal Theyleeb </li>
              <li>Onaiza</li>
            </ul>
            <p>In addition, there are many areas of Doha that give overseas investors the opportunity to lease for a remarkable 99 years. If you choose to live within the property, rather than renting it out, the health care and educational benefits of citizenship are provided! On that note, we will be happy to give you more information on these areas or any aspect of the purchasing process in Qatar.</p>
            <h4>A variety of mortgage options</h4>
            <p>Qatar’s real estate market continues to adapt technologically and methodically to optimise its efficiency and buying avenues. All types of buyers now have <a href="https://www.thepearlgates.com/qatar-property-services/other-services/mortgage-and-financing-services/">mortgage</a> choices that can be tailored to their affordability and purchasing location. </p>
            <h4>Apartments for sale in Doha, Qatar to suit a number of budgets</h4>
            <p>The Pearl Gates offers an extensive range of properties available to view above, and that you can filter results for. Prices range from around 800,000 AED to 8,000,000 AED for singles all the way to large families and discerning buyers. The choice is remarkable! We work directly with the best real estate developers across Qatar. </p>
            <p>What can you get between 900,000 AED and 1,000,000 AED? Imagine an 80 Sqft furnished 1 bedroom apartment. Utilities cost an attractive 300-500 AED per month. It includes a modern fully-furnished kitchen for home cooked meals or entertaining galore. Not to mention private parking in a state-of-the-art complex that certainly has 24 / 7 security, concierge service and even a swimming pool and gym to focus on fitness and relaxation whenever you wish.</p>   
            <p>On the other end of the price spectrum - what can you get with 6,000,000 AED?</p>                                 
            <p>A partly furnished, palatial 5 bedroom apartment that spans 460 Sqft, with a cost of 2000 - 24000 AED for utilities per month. Expect breathtaking views of the sparkling canals of Qanat Quartier. A property that includes extensive storage space, multiple ensuite bathrooms, magnificent living spaces and beach access.</p>
            <p>If you are looking to have a short stay or residency in Qatar, perhaps for business purposes, renting a property could likely be a better option than purchasing - we can assist with rental properties throughout the country.</p>
            <h4>Key advantages of buying apartments for sale in Qatar</h4>
            <ul>
              <li>No property tax - an option that buyers rarely have anywhere in the world.</li>
              <li>Unfurnished, part or fully furnished options.</li>
              <li>All types of properties are built wonderfully for many budgets.</li>
              <li>Increasing number of mortgage options to choose from.</li>
              <li>The Qatari government is actively making it easier for locals and overseas buyers to buy property quickly and at an affordable price.</li>
              <li>The Pearl Gates in Doha offer monthly payment plans to spread the cost of purchasing.</li>
            </ul>   
          </div> } */}
        {location.pathname.indexOf('type-apartments') >= 0 &&
          <div class="property-bottom-footer">
            <h4>Choosing the best apartments for sale in Qatar</h4>
            <p>You have set your thoughts on the potential of apartments for sale in Qatar, and what one, or perhaps several, could offer you! What do you envisage from the purchase? A high-quality, new apartment with low maintenance needs? Sipping a refreshing drink on a private balcony overlooking a dazzling Riviera? Perhaps a home within reach of business lounges, leisure hotspots and upscale restaurants? Or is it a real estate investment that encourages a growing rental yield?</p>
            <p>Despite economic dips experienced over the past, Qatar’s real estate market remains resilient and continues to develop with phenomenal architectural designs, sustainable innovations and eco-friendly aspects. Not to mention upcoming world-renowned sporting events in Qatar that will positively impact the market.</p>
            <p>Whatever your dream may be in Qatar, it is definitely possible with our local real estate experts who specialise in helping <a href="https://www.thepearlgates.com/qatar-property-services/buy/">buyers</a> choose the very best apartments for sale in Qatar or apartments for sale in Doha—Qatar’s shining jewel Capital that attracts expatriates in the thousands every year, thanks to the city’s progressive economic and infrastructural nature, and exponential career opportunities.</p>
            <h4>The creme de la creme of Gulf lifestyles for owners of apartments for sale in Qatar</h4>
            <p>A continual stream of new apartments for sale in Doha and across Qatar enter the market, along with beach-side villas, penthouses, stylish townhouses, duplexes and land. As you can imagine, all are built to an impeccable standard with a focus on convenience and spaciousness. They commonly include high-end detailing and contemporary fully-fitted kitchens.</p>
            <p>Qatar’s apartments are never far from daily necessities and services, be it a quick trip to the pharmacy, educational establishments held to high standards, or business premises in the hub of Doha city that sit central to major airports around the world.</p>
            <p>They are typically situated in emerging <a href="https://www.thepearlgates.com/living-in-dubai/">communities</a> with properties hitting the market right now, and continue to attract new and existing buyers from around the world and locals, too! These beautiful, new communities see increasing interest with our professional property consultants at <a href="https://www.thepearlgates.com/">The Pearl Gates</a> for above average ROIs and the high potential for growing property values. It’s true - there has never been a better time to buy apartments for sale in Doha and apartments for sale in Qatar than now.</p>
            <p>Up-and-coming areas gaining attention that we can help you explore the lifestyle and investment potential of.</p>
            <p>The Pearl | West Bay | West Bay Lagoon | Lusail City | Msheirab Downtown. </p>
            <h4>Locals, expats and investors foreign to Qatar alike are welcome to enjoy the benefits of apartments for sale in Qatar</h4>
            <p>Locals can enjoy access to all that Qatar’s property market offers. For buyers foreign to Qatar - whether resident expatriates or overseas investors - there are currently 10 freehold property purchasing areas with bountiful options for apartments for sale in and around Doha, Qatar.</p>
            <p>Freehold areas open to buyers foreign to Qatar:</p>
            <ul>
              <li>The Pearl, Qatar</li>
              <li>West Bay</li>
              <li>Al Khor Resort</li>
              <li>Raqdat Al Jahaniyah </li>
              <li>Al Wasail</li>
              <li>Al Khraji</li>
              <li>Al Qassar</li>
              <li>Al Dafna</li>
              <li>Jabal Theyleeb </li>
              <li>Onaiza</li>
            </ul>
            <p>In addition, there are many areas of Doha that give overseas investors the opportunity to lease for a remarkable 99 years. If you choose to live within the property, rather than renting it out, the health care and educational benefits of citizenship are provided! On that note, we will be happy to give you more information on these areas or any aspect of the purchasing process in Qatar.</p>
            <h4>A variety of mortgage options</h4>
            <p>Qatar’s real estate market continues to adapt technologically and methodically to optimise its efficiency and buying avenues. All types of buyers now have <a href="https://www.thepearlgates.com/qatar-property-services/other-services/mortgage-and-financing-services/">mortgage</a> choices that can be tailored to their affordability and purchasing location. </p>
            <h4>Apartments for sale in Doha, Qatar to suit a number of budgets</h4>
            <p>The Pearl Gates offers an extensive range of properties available to view above, and that you can filter results for. Prices range from around 800,000 AED to 8,000,000 AED for singles all the way to large families and discerning buyers. The choice is remarkable! We work directly with the best real estate developers across Qatar. </p>
            <p>What can you get between 900,000 AED and 1,000,000 AED? Imagine an 80 Sqft furnished 1 bedroom apartment. Utilities cost an attractive 300-500 AED per month. It includes a modern fully-furnished kitchen for home cooked meals or entertaining galore. Not to mention private parking in a state-of-the-art complex that certainly has 24 / 7 security, concierge service and even a swimming pool and gym to focus on fitness and relaxation whenever you wish.</p>   
            <p>On the other end of the price spectrum - what can you get with 6,000,000 AED?</p>                                 
            <p>A partly furnished, palatial 5 bedroom apartment that spans 460 Sqft, with a cost of 2000 - 24000 AED for utilities per month. Expect breathtaking views of the sparkling canals of Qanat Quartier. A property that includes extensive storage space, multiple ensuite bathrooms, magnificent living spaces and beach access.</p>
            <p>If you are looking to have a short stay or residency in Qatar, perhaps for business purposes, renting a property could likely be a better option than purchasing - we can assist with rental properties throughout the country.</p>
            <h4>Key advantages of buying apartments for sale in Qatar</h4>
            <ul>
              <li>No property tax - an option that buyers rarely have anywhere in the world.</li>
              <li>Unfurnished, part or fully furnished options.</li>
              <li>All types of properties are built wonderfully for many budgets.</li>
              <li>Increasing number of mortgage options to choose from.</li>
              <li>The Qatari government is actively making it easier for locals and overseas buyers to buy property quickly and at an affordable price.</li>
              <li>The Pearl Gates in Doha offer monthly payment plans to spread the cost of purchasing.</li>
            </ul>   
          </div>
        }
        {location.pathname.indexOf('type-villas') >= 0 &&
          <div class="property-bottom-footer">
            <h4>Selecting the perfect villas for sale in Qatar</h4>
            <p>You have decided it is time to consider jumping into a new lifestyle league in stunning, ever-developing Qatar. First, what do you imagine your villa life to be like? A villa for sale in Qatar that you can truly make your perfect new home? Waking up to tranquillity and starting your day knowing your workplace, the beach or leisure is within easy reach? Or perhaps it is an investment in real estate that offers healthy and growing rental returns?</p>
            <p>Just like economies around the world, Qatar's real estate market has experienced declines in the past. However, with new architectural and sustainable innovations, it remains resilient. Not to mention fast-approaching world-renowned sporting events in Qatar that will positively impact the real estate market.</p>
            <p>Thus, regardless of your goals in Qatar, <a href="https://www.thepearlgates.com/">The Pearl Gates</a> can help you make them a reality! Our experts are adept in helping <a href="https://www.thepearlgates.com/qatar-property-services/buy/">buyers</a> choose the right villas for sale in Qatar or villas for sale in Doha.</p>
            <h4>A dream lifestyle for owners of villas for sale in Qatar</h4>
            <p>The capital of Qatar, Doha, is the place for expatriates, attracting thousands every year. What motivates them to call Doha their home? Doha is one of the fastest growing cities in the world, which continues to focus on adapting its economy and advancing its environmental initiatives. The city is therefore full of career opportunities and offers great potential for expats and locals in all areas of life!</p>
            <p>As a result, new thoughtfully designed villas for sale in Doha and across Qatar continue to enter the market to accommodate a growing population. In addition to apartments, penthouses, townhouses, duplexes and land.</p>
            <p>Villas for sale in Doha, Qatar, are attractive to discerning buyers who want to live a more discreet lifestyle. They are built to top-tier standards, usually with high quality, tiled flooring and air conditioning throughout. They are undoubtedly spacious and have fantastic flow from room to room. Villas also often feature contemporary fully equipped kitchens and high-end detailing.</p>
            <ul>
              <li>Qatar's villas are available to buyers either as detached, semi-detached or part of a shared housing development.</li>
              <li>In addition, either furnished, semi-furnished or unfurnished depending on preference. The decision is entirely up to you!</li>
            </ul>
            <p>They are usually close to the breathtaking beaches of Doha, or have direct access to communal swimming pools, tennis courts and children's playgrounds. It is true - all the amenities you may need are only minutes away! Dropping children to recognised schools across Qatar or going to work in the city is therefore effortless. Not to mention high-end retail, dining and large parks.</p>
            <p>Most of Qatar's villas for sale are located in emerging communities throughout and around Doha. These new neighborhoods continue to attract more buyers from around the world! These stunning areas will therefore witness rising ROI and real estate prices over time. Yes, there has never been a better time to buy villas for sale in Doha, Qatar, than NOW.</p>
            <p>We can help you explore these rapidly growing areas:</p>
            <p>The Pearl | West Bay | West Bay Lagoon | Lusail City | Msheireb Downtown Doha</p>
            <h4>All types of buyers are now welcome to benefit from villas for sale in Qatar</h4>
            <p>Those with Qatari citizenship can easily enter the villa market. Over the years, however, it has not been as easy for foreigners to Qatar - whether they are resident expatriates or foreign investors - until recent years!</p>
            <p>There are currently 10 freehold areas where foreigners to Qatar can buy villas for sale in Doha, Qatar, with a wide range of options:</p>
            <ul>
              <li>The Pearl, Qatar</li>
              <li>West Bay</li>
              <li>Al Qassar</li>
              <li>Al Dafna</li>
              <li>Raqdat Al Jahaniyah</li> 
              <li>Jabal Theyleeb</li> 
              <li>Onaiza </li>
              <li>Al Khor Resort</li>
              <li>Al Wasail</li>
              <li>Al Khraji</li>
            </ul>
            <p>There are also a large number of areas in and around Doha that allow foreign buyers to lease for a phenomenal 99 years. If this relates to you, and you decide to live in the villa instead of renting it out, you will enjoy the benefits of health care and education as if you were a citizen! In this respect, we will be happy to guide you through your options in these areas or any aspect of the buying process in Qatar. </p>
            <h4>A growing number of mortgage options available</h4>
            <p>As Qatar's real estate market progresses technologically, the efficiency of property buying and selling increases. Thereby, all types of buyers now have more mortgage options than ever before, which can be tailored to affordability.</p>
            <h4>Villas for sale in Doha, Qatar that fit a wide range of budgets</h4>
            <p>We are happy to offer a collection of properties that suit a range of budgets, which you can view above and filter results for. Prices that appeal to singles, large families and those looking for a luxurious lifestyle. We work directly with the best real estate developers in Qatar - the preference is yours!</p>
            <p>The Pearl Gates options range from two-bedroom to five+ bedroom villas, and you will find our prices can be more affordable than with other real estate agents in Doha. You can discuss your budget with us so that we can identify realistic options.</p>
            <p>Are you looking to have a short stay or residency in Qatar? If yes, perhaps for business reasons, renting a property could be a better option for you than buying and we can assist with rental properties throughout Doha.</p>
            <h4>Key advantages of buying a villa in Qatar</h4>
            <ul>
              <li>No property tax - an option that buyers around the world rarely have.</li>
              <li>The Qatari government is actively making it easier for locals and foreigners to Qatar to buy villas quickly and at an affordable price.</li>
              <li>The Pearl Gates in Doha offer monthly payment plans to spread the cost of purchasing.</li>
            </ul>
          </div>
        }
        

     </Container>
	)
}
export default PropertyFooter;
